import "../styles/index.css"
import APropos from "./a-propos"

/*
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Seo from "../components/containers/seo"
import "../styles/index.css"
import APropos from './a-propos'
*/

// const IndexPage = () => {
//   const data = useStaticQuery(graphql`
//     query {
//       placeholderImage: file(relativePath: { eq: "under_construction.svg" }) {
//         publicURL
//       }
//     }
//   `)

//   return (
//     <>
//       <Seo title="Acceuil" />
//       <div className="index-content">
//         <div className="index-texts">
//           <h4>Site en construction</h4>
//           <p>Ne vous inquiétez pas, ce site sera bientôt disponible</p>
//         </div>
//         <img
//           src={data?.placeholderImage?.publicURL}
//           alt="site en construction"
//         />
//       </div>
//     </>
//   )
// }

export default APropos
